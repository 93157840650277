<template>
	<div class="full-container">
		<v-container>
			<v-row d-flex class="full-container-test-position">
				<v-col md="6" sm="12" cols="12">
					<div class="text-test-position-container">
						<div class="title-test-position">
							<h1>
								{{ userFirstName }}, on fait
								<br v-if="$vuetify.breakpoint.mdAndUp" />
								<span class="underlineText">connaissance ?</span>
							</h1>
						</div>
						<div class="description-test-position">
							<p>- Réponds aux <strong> 40 questions suivantes</strong></p>
							<p>- Tu as <strong> 30 secondes par question ⌛</strong></p>
							<br />
							<p>Cela nous permettra de te personnaliser ta formation et de générer ton rapport personnel.</p>
						</div>
						<div class="hvr-grow ortho-next-button" @click="goToTestPosition()">
							C'est parti
							<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<img src="@/assets/images/img-presentation-story-big.png" class="image-test-position" alt="" />
		<loading :show="loading_show" :label="loading_label"> </loading>
	</div>
</template>

<script>
import loading from 'vue-full-loading';

export default {
	components: {
		loading,
	},
	props: {
		userFirstName: String,
	},
	data() {
		return {
			loading_show: false,
			loading_label: '3, 2, 1...',
			loading_timeOut: 2000,
		};
	},
	methods: {
		goToTestPosition() {
			this.loading_show = true;
			setTimeout(() => {
				this.loading_show = false;
				this.$emit('clicked', 'startTest');
			}, this.loading_timeOut);
		},
	},
};
</script>

<style scoped>
.flow {
	width: 100%;
}
.full-container {
	background-color: #f8f8f8;
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: relative;
}
.description-test-position {
	margin-top: 20px;
	font-size: 20px;
}
.ortho-next-button {
	margin-bottom: 12%;
}
.full-container-test-position {
	margin-top: 150px;
}
.text-test-position-container {
	text-align: left;
}
.title-test-position {
	font-weight: 900;
	line-height: 1;
	font-size: 35px;
}
.image-test-position {
	position: absolute;
	right: 0px;
	bottom: 0px;
	left: 65%;
	max-width: 23rem;
}
@media only screen and (max-width: 1300px) {
	.image-test-position-container {
		right: -20%;
	}
}
@media only screen and (max-width: 960px) {
	.image-test-position {
		display: none;
	}
}
@media only screen and (max-width: 600px) {
	.description-test-position {
		margin-top: 20px;
		font-size: 18px;
	}
	.full-container {
		position: relative;
	}
	.text-test-position-container {
		text-align: center;
		padding-left: 0px;
		padding-right: 0px;
	}
	.title-test-position {
		font-size: 20px;
	}
	.image-test-position {
		display: none;
	}
}
</style>
